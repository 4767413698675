<template>
  <v-container
    class="grid grid-cols-[1fr_1fr_2fr] items-start gap-16 py-24 text-13 md:grid-cols-1 md:pt-12"
    :class="[
      route.meta.footerClass,
      {
        'text-white': props.white,
        'xl:grid-cols-2': props.narrow,
      },
    ]"
  >
    <div>
      <p class="font-bold">Столкнулись с проблемой?</p>
      <ul>
        <li v-if="$publicConfig.PHONE">
          Телефон:
          <a
            :class="props.white ? 'text-white' : 'text-2c58f3'"
            :href="`tel:${$publicConfig.PHONE.replace(/[^0-9\+]/g, '')}`"
            target="_blank"
          >
            {{ $publicConfig.PHONE }}
          </a>
        </li>

        <li v-if="$publicConfig.EMAIL">
          Email:
          <a
            :class="props.white ? 'text-white' : 'text-2c58f3'"
            :href="`mailto:${$publicConfig.EMAIL}`"
            target="_blank"
          >
            {{ $publicConfig.EMAIL }}
          </a>
        </li>
        <li v-if="$publicConfig.TELEGRAM_USERNAME">
          Телеграм:
          <a
            :class="props.white ? 'text-white' : 'text-2c58f3'"
            :href="`https://t.me/${$publicConfig.TELEGRAM_USERNAME}`"
            target="_blank"
          >
            @{{ $publicConfig.TELEGRAM_USERNAME }}
          </a>
        </li>
        <li v-if="!props.noInstruction">
          <button
            class="text-2c58f3"
            @click="modals.show('instruction')"
          >
            Инструкция по работе с сервисом
          </button>
        </li>
      </ul>
    </div>

    <div>
      <p
        class="whitespace-pre"
        v-html="$publicConfig.ORGANIZATION"
      ></p>
      <a
        :href="$publicConfig.OFFER_AGREEMENT_LINK"
        target="_blank"
        class="inline-block w-fit"
        :class="props.white ? 'text-white' : 'text-2c58f3'"
      >
        Договор оферты
      </a>
      <div></div>
      <a
        :href="$publicConfig.PRIVACY_POLICY_LINK"
        target="_blank"
        class="inline-block w-fit"
        :class="props.white ? 'text-white' : 'text-2c58f3'"
      >
        Политика конфиденциальности
      </a>
    </div>

    <p class="xl:col-span-2 md:col-span-1">
      {{ $publicConfig.DISCLAIMER }}
    </p>
  </v-container>
</template>

<script setup lang="ts">
const props = defineProps<{
  white?: boolean
  narrow?: boolean
  noInstruction?: boolean
}>()

const route = useRoute()

const modals = useModalsStore()
</script>
